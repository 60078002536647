<template>
    <div>
        Scedule page
    </div>  
</template>

<script>
export default {

}
</script>

<style>

</style>